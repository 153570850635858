import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import AboveTheFold from './AboveTheFold'
import Grid from './Grid'

const Main = styled.div`
  width: 100%;
`

const query = graphql`
  query {
    airhouse: file(relativePath: { regex: "/investments/airhouse.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    calm: file(relativePath: { regex: "/investments/calm.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    caraway: file(relativePath: { regex: "/investments/caraway.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    catalyst: file(relativePath: { regex: "/investments/catalyst.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    classPass: file(relativePath: { regex: "/investments/classpass.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    classPass: file(relativePath: { regex: "/investments/classpass.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    databricks: file(relativePath: { regex: "/investments/databricks.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dailyHarvest: file(relativePath: { regex: "/investments/daily-harvest.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digitalOcean: file(relativePath: { regex: "/investments/digital-ocean.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    functionHealth: file(relativePath: { regex: "/investments/function-health.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    starday: file(relativePath: { regex: "/investments/starday.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    getCovered: file(relativePath: { regex: "/investments/get-covered.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ghia: file(relativePath: { regex: "/investments/ghia.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    goodLeap: file(relativePath: { regex: "/investments/good-leap.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hykuHome: file(relativePath: { regex: "/investments/hyku-home.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hims: file(relativePath: { regex: "/investments/hims.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lyft: file(relativePath: { regex: "/investments/lyft.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    parallel: file(relativePath: { regex: "/investments/parallel.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    perelel: file(relativePath: { regex: "/investments/perelel.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pinterest: file(relativePath: { regex: "/investments/pinterest.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rho: file(relativePath: { regex: "/investments/rho.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    saturn: file(relativePath: { regex: "/investments/saturn.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    seed: file(relativePath: { regex: "/investments/seed.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    settle: file(relativePath: { regex: "/investments/settle.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smartrr: file(relativePath: { regex: "/investments/smartrr.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    telltail: file(relativePath: { regex: "/investments/telltail.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tenThousand: file(relativePath: { regex: "/investments/ten-thousand.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tydo: file(relativePath: { regex: "/investments/tydo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kasheesh: file(relativePath: { regex: "/investments/kasheesh.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bounty: file(relativePath: { regex: "/investments/bounty.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bridge: file(relativePath: { regex: "/investments/bridge.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    highbeam: file(relativePath: { regex: "/investments/highbeam.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    canopy: file(relativePath: { regex: "/investments/canopy.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Investments = () => {

  const {
    airhouse,
    calm,
    caraway,
    catalyst,
    classPass,
    databricks,
    dailyHarvest,
    digitalOcean,
    functionHealth,
    starday,
    getCovered,
    ghia,
    goodLeap,
    hykuHome,
    hims,
    lyft,
    parallel,
    perelel,
    pinterest,
    rho,
    saturn,
    seed,
    settle,
    smartrr,
    telltail,
    tenThousand,
    tydo,
    kasheesh,
    bounty,
    bridge,
    highbeam,
    canopy
  } = useStaticQuery(query)

  const investments = [
    {
      name: 'Airhouse',
      category: 'E-commerce Operations',
      url: 'https://www.airhouse.io/',
      img: airhouse.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Bounty',
      category: 'SaaS, Marketing',
      url: 'https://www.bounty.co/',
      img: bounty.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Bridge',
      category: 'Cloud Platforms',
      url: 'https://www.bounty.co/',
      img: bridge.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Calm',
      category: 'Health & Wellness',
      url: 'https://www.calm.com/',
      img: calm.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Caraway',
      category: 'Consumer Goods',
      url: 'https://www.carawayhome.com/',
      img: caraway.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Catalyst',
      category: 'SaaS, Fintech',
      url: 'https://catalyst.io/',
      img: catalyst.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'ClassPass',
      category: 'Fitness',
      url: 'https://classpass.com/',
      img: classPass.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Databricks',
      category: 'Computer Software',
      url: 'https://www.databricks.com/',
      img: databricks.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Daily Harvest',
      category: 'Food & Beverage',
      url: 'https://www.daily-harvest.com/',
      img: dailyHarvest.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Digital Ocean',
      category: 'Cloud Platforms',
      url: 'https://www.digitalocean.com/',
      img: digitalOcean.childImageSharp.fluid,
      exited: true
    },
    {
      name: 'Function Health',
      category: 'Health & Wellness',
      url: 'https://www.functionhealth.com/',
      img: functionHealth.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Starday',
      category: 'Food & Beverage',
      url: 'https://www.stardayfoods.com/',
      img: starday.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Get Covered',
      category: 'Real Estate',
      url: 'https://getcovered.io/',
      img: getCovered.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Ghia',
      category: 'Food & Beverage',
      url: 'https://drinkghia.com/',
      img: ghia.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'GoodLeap',
      category: 'Sustainability',
      url: 'https://goodleap.com/',
      img: goodLeap.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Highbeam',
      category: 'Cloud Platforms',
      url: 'https://www.highbeam.co/',
      img: highbeam.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Canopy',
      category: 'Consumer Goods',
      url: 'https://getcanopy.co/',
      img: canopy.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Hims',
      category: 'Health & Wellness',
      url: 'https://www.forhims.com/',
      img: hims.childImageSharp.fluid,
      exited: true
    },
    {
      name: 'Lyft',
      category: 'Transportation',
      url: 'https://www.lyft.com/',
      img: lyft.childImageSharp.fluid,
      exited: true
    },
    {
      name: 'Parallel',
      category: 'Retail',
      url: 'https://www.useparallel.com/',
      img: parallel.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Perelel',
      category: 'Health & Wellness',
      url: 'https://perelelhealth.com/',
      img: perelel.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Pinterest',
      category: 'Internet',
      url: 'https://www.pinterest.com/',
      img: pinterest.childImageSharp.fluid,
      exited: true
    },
    {
      name: 'Rho',
      category: 'SaaS, Fintech',
      url: 'https://www.rho.co/',
      img: rho.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Saturn',
      category: 'Internet',
      url: 'https://www.joinsaturn.com/',
      img: saturn.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Seed',
      category: 'Health & Wellness',
      url: 'https://seed.com/',
      img: seed.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Settle',
      category: 'Saas, Fintech',
      url: 'https://www.settle.com/',
      img: settle.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Smartrr',
      category: 'SaaS, Fintech',
      url: 'https://smartrr.com/',
      img: smartrr.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'TellTail',
      category: 'Platform Service',
      url: 'https://telltail.com/',
      img: telltail.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Ten Thousand',
      category: 'Retail',
      url: 'https://www.tenthousand.cc/',
      img: tenThousand.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Tydo',
      category: 'SaaS, Marketing',
      url: 'https://www.tydo.com/',
      img: tydo.childImageSharp.fluid,
      exited: false
    },
    {
      name: 'Kasheesh',
      category: 'SaaS, Fintech',
      url: 'https://www.kasheesh.co/',
      img: kasheesh.childImageSharp.fluid,
      exited: false
    },
  ]  

  return (
    <Main>
      <AboveTheFold />
      <Grid investments={investments} />
    </Main>
  )
}

export default Investments