import React from 'react'
import styled from 'styled-components'
import Card from './Card'
import { media } from '../utils/style-utils.js'

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 30px 20px;

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
  `}
  ${media.medium`
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  `}
  ${media.large`
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  `}
`
const Container = styled.div`
  max-width: 1268px;
  width: 100%;
  margin: 60px auto;
`

const Grid = ({ investments }) => {
  return (
    <Container>
      <GridWrapper>
        {investments.map(item => (
          <Card key={item.name} {...item} />
        ))}
      </GridWrapper>
    </Container>
  )
}

export default Grid