import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { H2, Gotham } from '../shared/text'
import { media } from '../utils/style-utils.js'

const CardWrapper = styled.div`
  display:  flex;
  flex-direction: column;
  gap: 13px;
  overflow: hidden;

  ${media.small`
    ${H2} {
      color: ${props => props.theme.colors.azure};
      font-size: 24px;
      line-height: 1;
    }
  `}

  ${media.large`
    ${H2} {
      color: ${props => props.theme.colors.azure};
      font-size: 33px;
      line-height: 43px;
    }
  `}
`

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  max-height: 270px;
  background-color: #EBEBEB;
  ${media.small`
    ${Img} {
      max-height: 150px;
    }
  `}

  ${media.large`
    ${Img} {
        max-height: 270px;
    }
  `}

`

const IndustryPill = styled.div`

  width: fit-content;
  height: 32px;
  display: grid;
  place-items: center;

  background-color: ${props => props.theme.colors.alabaster};
  border: 1px solid ${props => props.theme.colors.alabaster};
  border-radius: 50px;

  line-height: 1;
  color: ${props => props.theme.colors.azure};
  text-transform: uppercase;

  padding: 4px 13px 4px 13px;
  margin-bottom: 22px;
  

  transition: 300ms ease all;

  ${media.small`
    ${Gotham} {
      font-size: 11px;
    }
  `}

  ${media.large`
    ${Gotham} {
      font-size: 13px;
    }
  `}

`

const Exited = styled.span`
  position: absolute;
  bottom: 14px;
  right: 14px;
  background-color: ${props => props.theme.colors.azure};
  font-family: Apercu, sans-serif;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.small`
    width: 35%;
    height: 35%;
    font-size: 100%;
  `}

  ${media.large`
    font-size: 24px;
    width: 107px;
    height: 107px;
  `}

`

const Card = ({ url, name, img, category, exited = true  }) => {
  console.log()
  return (
    <CardWrapper>
      <ImageWrapper
        as="a"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {img && (
          <Img
            fluid={img}
            alt={name}
            style={{ height: '100%', width: '100%' }}
          />
        )}
        {exited && (
          <Exited>
            exited
          </Exited>
        )}
      </ImageWrapper>
      <H2>{name}</H2>
      <IndustryPill>
        <Gotham>{category}</Gotham>
      </IndustryPill>
    </CardWrapper>
  )
}

export default Card