import React from 'react'

import SEO from '../../components/shared/SEO'
import Layout from '../../components/Layout'
import Investments from '../../components/Investments'

const InvestedPage = ({ location }) => {
  return (
    <>
      <SEO
        title="Investing in the brands of the future"
        description="We invest in the next generation of companies bringing positive change to the world."
      />
      <Layout location={location}>
        <Investments />
      </Layout>
    </>
  )
}

export default InvestedPage